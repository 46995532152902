import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { AuthModel } from './models/auth.model';
import _ from 'lodash';
import { AuthError } from './models/auth-error.model';
import { HttpClient } from '@angular/common/http';
import { DecentralierService } from '@shared/directives/zaa-decentralization/decentralization.services';
import { API_ENDPOINTS } from '@config/api.config';
import { AppStorageService, StorageLocation } from '@core/app-store/app-storage.service';
import { JWTHelpler } from '@shared/helpers/jwt/jwt.helper';
import { environment } from '@config/environments/environment';
import { ILoginResponse } from './interfaces/login-response.interface';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly AUTH_ENVIRONMENT = environment.application.metadata;
  private readonly STORAGE_LOCATION = { location: StorageLocation.LOCAL_STORAGE };
  private readonly API_ENDPIONTS = {
    LOGIN: `${API_ENDPOINTS.auth}/login`,
    LOGIN_BY_TOKEN: `${API_ENDPOINTS.auth}/login-token`,
  }

  private authModel!: AuthModel;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly detranalizerService: DecentralierService,
    private readonly appStorage: AppStorageService,
  ) {
    this.authModel = new AuthModel()
  }

  login(username: string, password: string): Observable<AuthModel> {
    if (username?.trim().length == 0 || password.trim().length == 0) {
      throw new Error(`Username and password should not be empty`);
    }
    const url = this.API_ENDPIONTS.LOGIN;
    const requestBody = {
      username: username,
      password: password,
      captcha: this.AUTH_ENVIRONMENT.capcha,
      app: this.AUTH_ENVIRONMENT.app,
      diuu: this.AUTH_ENVIRONMENT.diuu,
      key_option_xac_thuc: this.AUTH_ENVIRONMENT.key_option_xac_thuc,
    };

    const response = this.httpClient.post<ILoginResponse>(url, requestBody);

    return response.pipe(
      mergeMap((response: any) => {
        if (response?.isSuccess === false && response.isFailure === true) {
          return throwError(() => new AuthError(response))
        }
        return of(response);
      }),
      map((response: any) => this.authModel.mapDataFromAPI(response)),
      tap((formated) => {
        this.setAuthResponseToStorage(formated.token, formated.result, formated.listmenu_active);
      })
    );
  }

  // ex http://localhost:4200/?redirect=nqld/cai-dat/tai-khoan&token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjE5MzciLCJOaGFuVmllbklEIjoiTk5WMDA1MDU4OCIsImFwcCI6Ik1PQklMRV9IVVRFQ0giLCJpcCI6Ijo6ZmZmZjoxMjcuMC4wLjEiLCJzZXJ2aWNlX2lkIjoiMTkzNyIsImlhdCI6MTY5ODI0Mjk4ODcxMCwidHlwZSI6IkxPR0lOX1RPS0VOIiwiZXhwIjoxNjk4MjQyOTg4NzU4fQ.wPEvTpCfupvuHOu6q1EXjhESr4H7oqDj_AAr6JRuDUA
  handleAuthenticateByToken(token: string) {
    if (token?.length == 0) {
      throw new Error(`Token should not be empty!`);
    }
    if (!new JWTHelpler().idValid(token)) {
      throw new Error(`Invalid access token - need to login again!`);
    }

    this.logout();
    const { accessToken } = this.appStorage.localStorageKeys.feature.auth;
    // Set token to storage to use auth header in global
    this.appStorage.setItem(accessToken, token, this.STORAGE_LOCATION);

    return this.loginByTokenRequest().pipe(tap((response) => {
      // NOTE:Logic set accesss token when navigate to localstorage - dont use response access token!!!
      this.setAuthResponseToStorage(token, response.result, response.listmenu_active);
    }))
  }

  setAuthResponseToStorage(token: string, authInfo: object, listmenu: string[] | number[]) {
    const { accessToken, actionIds, userInformation } =
      this.appStorage.localStorageKeys.feature.auth;

    this.appStorage.setItem(accessToken, token, this.STORAGE_LOCATION);
    this.appStorage.setItem(userInformation, authInfo, this.STORAGE_LOCATION);
    this.appStorage.setItem(actionIds, listmenu, this.STORAGE_LOCATION);
  }

  loginByTokenRequest = (): Observable<AuthModel> => {
    const endpoint = this.API_ENDPIONTS.LOGIN_BY_TOKEN;
    return this.httpClient.post<ILoginResponse>(endpoint, {}).pipe(
      map((response) => this.authModel.mapDataFromAPI(response)),
    );
  }

  logout(): void {
    const { tabSetting, themeSetting } = this.appStorage.localStorageKeys.feature.layouts;
    const language = this.appStorage.localStorageKeys.global.transloco.currentLanguage;
    // Get ra rồi gán vô lại đở tốn xử lý hơn tìm cho đúng rồi remove né cái đó ra
    const currentThemeMode = this.appStorage.getItem<any>(tabSetting.themeMode, this.STORAGE_LOCATION);
    const currentTabSetting = this.appStorage.getItem<any>(themeSetting, this.STORAGE_LOCATION);
    const currentLangulage = this.appStorage.getItem<any>(language, this.STORAGE_LOCATION);

    this.appStorage.removeAllIn(this.STORAGE_LOCATION);
    this.detranalizerService.setElements([]); // <--- Clear active element in state;

    this.appStorage.setItem(tabSetting.themeMode, currentThemeMode, this.STORAGE_LOCATION);
    this.appStorage.setItem(themeSetting, currentTabSetting, this.STORAGE_LOCATION);
    this.appStorage.setItem(language, currentLangulage, this.STORAGE_LOCATION);
  }

  isLoggedIn = (): boolean => {
    return new JWTHelpler().idValid(this.getAccessToken());
  }

  getAccessToken = () => {
    const token = this.appStorage.getItem<string>(
      this.appStorage.localStorageKeys.feature.auth.accessToken,
      this.STORAGE_LOCATION
    );
    return token;
  }

  buildTokenByJWTPrefix = () => {
    return `JWT ${this.getAccessToken()}`;
  }
}
